import { render, staticRenderFns } from "./ArticleMeta.vue?vue&type=template&id=30c75925&scoped=true&"
var script = {}
import style0 from "./ArticleMeta.vue?vue&type=style&index=0&id=30c75925&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../Volumes/scratch/tmp/scum-hrd2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c75925",
  null
  
)

export default component.exports