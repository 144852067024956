import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=4a602b4f&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=4a602b4f&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../Volumes/scratch/tmp/scum-hrd2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a602b4f",
  null
  
)

export default component.exports