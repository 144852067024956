<template>
  <pds-header>
    <a href="/">
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <img
        class="navlogo"
        alt="pagedaemon logo"
        :src="require('@/../assets/pdlogo.png')"/>
    </a>
    pagedaemon.org

    <template v-slot:tabs>
      <mu-tabs
        :value.sync="tabActive"
        inverse
        indicator-color="primary">
        <mu-tab
          href="/blog/"
          value="/blog/">
          Blog
        </mu-tab>
        <mu-tab
          href="/about/"
          value="/about/">
          About
        </mu-tab>
        <mu-tab
          href="https://twitter.com/pagedaemon"
          value="https://twitter.com/pagedaemon">
          <i class="icon ion-logo-twitter"/>
        </mu-tab>
      </mu-tabs>
    </template>
  </pds-header>
</template>

<script>
export default {
  data () {
    return {
      tabActive: this.pathBase()
    }
  },
  methods: {
    pathBase () {
      // remove all but root and first subdir, leaving trailing '/'.
      let parts = window.location.pathname.split('/', 3)
      // parts[0] is always blank, parts[1] is the first subdir.
      if (parts.length < 3) {
        return '/'
      }
      return '/' + parts[1] + '/'
    }
  }
}
</script>

<style scoped lang="less">
img.navlogo {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.2rem;
  vertical-align: middle;
}
</style>
