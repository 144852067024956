import Vue from 'vue'
import {
  Button,
  Grid,
  Tabs
} from 'muse-ui'
import theme from 'muse-ui/lib/theme'
import { defaultTheme, Breadcrumbs, Header } from 'pds/style'

import Article from '@/Article.vue'
import ArticleMeta from '@/ArticleMeta.vue'
import PageHeader from '@/PageHeader.vue'

import 'muse-ui/lib/styles/theme.less'
import 'pds/style/style.less'
import '@/style.less'

Vue.use(Button)
Vue.use(Grid)
Vue.use(Tabs)

Vue.component('pds-breadcrumbs', Breadcrumbs)
Vue.component('pds-header', Header)
Vue.component('sh-article', Article)
Vue.component('sh-article-meta', ArticleMeta)
Vue.component('sh-pageheader', PageHeader)

let initResolve = null
let initDone = new Promise((resolve, reject) => { initResolve = resolve })

window.addEventListener('DOMContentLoaded', () => {
  theme.add('pds', defaultTheme, 'light')
  theme.use('pds')

  let pageDiv = document.querySelector('div#page')

  if (pageDiv !== null) {
    /* eslint-disable no-new */
    new Vue({
      el: pageDiv
    })
  }
  initResolve()
})

export { Vue, theme, defaultTheme, initDone }
